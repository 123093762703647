/*  Bootstrap Clearfix auto-clear */
.app-news5-admin-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .toolbar {
      display:flex;
      ul {
        padding-left: 10px;

        &:first-child {
          padding-left: 0;
        }
      }
  }
  span {
      line-height:28px;
      font-size:14px;
      color:#666;
  }
  .sc-menu {
      li {
          display:inline-block;
          a{
              div{

                    background:#666;
              }
          }
      }
  }
}
@mixin row-first-child($col-type) {
  .col-#{$col-type}- {
    &1:nth-child(12n + 1),
    &2:nth-child(6n + 1),
    &3:nth-child(4n + 1),
    &4:nth-child(3n + 1),
    &6:nth-child(odd) {
      clear: left;
    }
  }
}

.auto-clear {
  @include media-breakpoint-up(xl) {
    @include row-first-child(lg);
  }
  @include media-breakpoint-up(lg) {
    @include row-first-child(md);
  }
  @include media-breakpoint-up(md) {
    @include row-first-child(sm);
  }
  @include media-breakpoint-up(sm) {
    @include row-first-child(xs);
  }
}

.app-news5-start-list {
  display: flex;
  height: 100%;
  border-top: 1px solid #009a5a;
  position: relative;


  h2.h6 {
    position: absolute;
    top: 0;
    left: 15px;
  }

  .d-flex {
    display: flex;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }  

  .flex-column {
    flex-direction: column;
  }

  .py-1 {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .row {
    display: flex;
    margin: 0;

    .col-12 {
      float: none;
      flex-grow: 1;
    }
  }

  .app-news5-date {
    font-size: 24px;
    margin-bottom: 12px;
    line-height: 32px;
    color: #009a5a;
  }

  .app-news5-details-link {
    border-bottom: 1px solid #009a5a;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    .link-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

    }
  }

  h3 {
    margin: 0;
  }
}

.link-allnews {
  display: inline-block;
  margin: 5px 15px 15px;
}